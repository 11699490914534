import BuidHTMLElement from './BuildHTMLElement'

const PROMOCODE_SHOW_DELAY = 8000;

const url = new URL(window.location.href);
const utm_source = url.searchParams.get("utm_source");
const utm_campaign = url.searchParams.get("utm_campaign");
const utm_content = url.searchParams.get("utm_content");

let promocode = false;

if (utm_source == 'OK_RD' && utm_campaign == 'chinch_2022' && utm_content == 'video_post1') promocode = 'ЧИНЧ132';
if (utm_source == 'myTargetRD' && utm_campaign == 'chinch_2022' && utm_content == 'teaser1') promocode = 'ЧИНЧ423';
if (utm_source == 'VK_RD') promocode = 'ЧИНЧ132';

if (utm_source == 'yandex_direct') {
  const yaCampaigns = {
    'yandex-search-brand-ecom': 'ЧИНЧ132',
    'yandex-search-competitors-ecom': 'ЧИНЧ271',
    'yandex-search-generic-ecom': 'ЧИНЧ326',
    'yandex-network-lal': 'ЧИНЧ326',
    'yandex-search-brand': 'ЧИНЧ132',
    'yandex-search-competitors': 'ЧИНЧ271',
    'yandex-search-generic': 'ЧИНЧ326',
  }
  promocode = yaCampaigns[utm_campaign] ?? false;
}

if (promocode) {
  const modal = BuidHTMLElement({
    tag: 'div',
    params: {
      className: 'modal modal-promocode',
      id: 'modal-promocode',
    }
  });

  const modalTitle = BuidHTMLElement({
    tag: 'div',
    params: {
      className: 'modal__title',
      innerHTML: 'Ваш промокод!',
    }
  });
  
  const modalContent = BuidHTMLElement({
    tag: 'div',
    params: {
      className: 'modal__content',
      innerHTML: `
      <div class="modal__text">Ваш персональный промокод на покупку первой упаковки ЧИНЧ со скидкой 10% на <a href="https://apteka.ru/product/chinch-60-sht-kapsuly-massoj-032-g-5e326e0565b5ab0001653efe/" class="modal__promocode_link"></a></div>
      <div class="modal__promocode">${promocode}</div>
      `,
    }
  });
  
  const modalClose = BuidHTMLElement({
    tag: 'div',
    params: {
      className: 'modal__close',
    }
  });

  modal.appendChild(modalClose);
  modal.appendChild(modalTitle);
  modal.appendChild(modalContent);

  modalClose.addEventListener('click', e => {
    e.preventDefault();
    modal.classList.remove('modal-active');
  })
  modalContent.addEventListener('click', e => {
    if (e.target.classList.contains('modal__promocode')) {
      e.preventDefault();
      const promocodeElem = e.target;
      navigator.clipboard.writeText(e.target.innerText)
      .then(() => {
        promocodeElem.classList.add('modal__promocode-copied');
        promocodeElem.innerHTML = 'Промокод скопирован';
        
        setTimeout(() => {
          promocodeElem.classList.remove('modal__promocode-copied');
          promocodeElem.innerHTML = promocode;
        }, 2000)
      })
      .catch(err => {
        console.error(err);
      });
    }

  })

  document.body.appendChild(modal);
  
  setTimeout(() => {
    modal.classList.add('modal-active')
  }, PROMOCODE_SHOW_DELAY);
}