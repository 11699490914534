import Swiper, { Navigation, Pagination } from 'swiper';
import ScrollTo from './scrollTo'

import './promocode'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import '../scss/style.scss';

const faqItems = document.querySelectorAll('.faq__item');
const menu = document.querySelector('.header__rightside');
const header = document.querySelector('.header');
faqItems.forEach(el => el.addEventListener('click', (e) => (e.target.classList.contains('faq__title')) ? el.classList.toggle('faq__item-active') : null));


const getWindowWidth = () => {
  return window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;
  
}
const openMenu = () => {
  menuBtn.classList.toggle('menu_icon-active');
  menu.classList.toggle('header__rightside-active');
  header.classList.toggle('header-active');
}
const closeMenu = () => {
  menuBtn.classList.remove('menu_icon-active');
  menu.classList.remove('header__rightside-active');
  header.classList.remove('header-active');
}
const menuBtn = document.querySelector('.menu_icon');
menuBtn.addEventListener('click', ()=> openMenu())

const navLinks = document.querySelectorAll('.nav__link');
navLinks.forEach(el  => el.addEventListener('click', e => {
  if (el.getAttribute('href').charAt(0) == "#"){
    e.preventDefault();
    closeMenu();
    ScrollTo(document.querySelector(el.getAttribute('href')), 500, (getWindowWidth() >= 992)?header:null);
  }
  })
);

document.addEventListener('scroll', ()=>{
  let screen1 = document.querySelector('.subsection-1');
  if (screen1 && document.documentElement.clientWidth > 991){
    var rect = screen1.getBoundingClientRect();
    let top = rect.top + window.pageYOffset || document.documentElement.scrollTop;
    if (pageYOffset > top){
      header.classList.add('header-fixed');
    } else{
      header.classList.remove('header-fixed');
    }
  }
})

Swiper.use([Navigation, Pagination]);


let swiper = [];
let swiperArr = ['.section-2', '.section-5', '.section-5--add']
let breakPoint = 768;

const sliderInit = () => {
  swiperArr.forEach((el, i) => swiper[i] = new Swiper(`${el}__items`, {
      loop: false,
      slidesPerView: 1,
      navigation: {
        nextEl: `${el}__navigation-next`,
        prevEl: `${el}__navigation-prev`,
      }
    })
  )
}

const resizeHandlerSlider = () => {
    if (swiper.length == 0 && document.documentElement.clientWidth < breakPoint) {
      sliderInit();
    } 
    if (swiper.length > 0 && document.documentElement.clientWidth > breakPoint) {
      swiper.forEach(el => el.destroy());
      swiper = [];
    }
}
resizeHandlerSlider();
window.addEventListener('resize', resizeHandlerSlider);

const saleFixed = document.querySelector('.sale-fixed');
if (saleFixed) {
  saleFixed.addEventListener('click', (e) => {
    e.preventDefault();
    ScrollTo(document.querySelector(saleFixed.getAttribute('href')), 500, (getWindowWidth() >= 992)?header:null);
  })
}

/*
let touch = 0;
let canMoveMenu = false;
window.addEventListener('touchstart', (e)=>{
  canMoveMenu = true;
  e.path.forEach(el => {
    canMoveMenu = !el.classList.contains('swiper-wrapper') && canMoveMenu;
  })
  touch = +e.changedTouches[0].clientX;
});
window.addEventListener('touchmove', (e)=>{
  if (canMoveMenu){
    if (e.changedTouches[0].clientX - touch >= 100 && !header.classList.contains('header-active')){
      header.style.transform = `translateX(calc(-100% + ${e.changedTouches[0].clientX - touch - 100}px))`;
    } else if (touch - e.changedTouches[0].clientX >= 50 && header.classList.contains('header-active')){
      header.style.transform = `translateX(-${touch - e.changedTouches[0].clientX - 50}px)`;
    }
  }
});
window.addEventListener('touchend', (e)=>{
  if (canMoveMenu){
    if (e.changedTouches[0].clientX - touch >= 100 && !header.classList.contains('header-active')){
      header.style.transform = `translateX(0)`;
      openMenu();
    } else if (touch - e.changedTouches[0].clientX >= 50 && header.classList.contains('header-active')){
      closeMenu();
      header.removeAttribute('style');
    }
    else{
      header.removeAttribute('style');
    }
    touch = 0;
    canMoveMenu = false;
  }
});*/